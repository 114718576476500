<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('payment-method-list')"
        @save="createItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">
              <hr>
              <div>

                <b-row>
                  <b-col cols="6">
                    <b-form-group :label="$t('Name')">
                      <validation-provider #default="{errors}" :name=" $t('Name')"
                                           rules="required">
                        <b-form-input v-model="user_sepa_debit.name" :placeholder="$t('Name')"/>

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="Object.keys(validation).includes('name')" class="text-danger">{{
                            $t(validation.name[0])
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider #default="validationContext"
                                         :name="$t('Account owner')" rules="required"
                    >
                      <b-form-group :label="$t('Account owner')">
                        <b-form-input v-model="user_sepa_debit.account_owner"
                                      :state="getValidationState(validationContext)"
                                      :placeholder="$t('Account owner')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group :label="$t('IBAN')">
                      <validation-provider #default="{errors}" :name="$t('IBAN')"
                                           rules="required">
                        <b-form-input v-model="user_sepa_debit.sepa_debit.iban"
                                      :placeholder="$t('DE## #### #### #### #### #### ##')" :name="$t('IBAN')"
                                      v-mask="'DE## #### #### #### #### #### ##'"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="Object.keys(validation).includes('sepa_debit.iban')" class="text-danger">{{
                            $t(validation['sepa_debit.iban'][0])
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider #default="validationContext"
                                         :name="$t('BIC')" rules="required|bic"
                    >
                      <b-form-group :label="$t('BIC')">
                        <b-form-input v-model="user_sepa_debit.sepa_debit.bic"
                                      :state="getValidationState(validationContext)"
                                      :placeholder="$t('BIC')"
                                      :name="$t('BIC')"
                                      v-mask="['AAAAAAAA', 'AAAAAAAAAAA']"
                        />

                        <b-form-invalid-feedback>
                          {{ $t(validationContext.errors[0]) }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <div class="d-flex mt-1">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success" type="submit" @click.prevent="createItem">
                    {{ $t('Add Payment Method') }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </component>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider, extend} from 'vee-validate'
import {alphaNum, email, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import {mask} from 'vue-the-mask'
import BCardActions from '@core/components/b-card-actions/BCardActions'

extend('bic', {
  validate(value) {
    const res1 = value.length == 8;
    const res2 = value.length == 11;

    return (res1 || res2);
  },
  params: [],
  message: 'The BIC must be have the length 8 or 11.'
});

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    required,
    alphaNum,
    email,
    BFormCheckbox,
    BFormRadio,
    // Form Validation
    BCardActions,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  directives: {
    Ripple,
    mask,
  },
  data() {
    return {

      user_credit: {
        name: '',
        card: {
          number: '',
          exp_month: '',
          exp_year: '',
          cvc: '',
        },
      },

      user_sepa_debit: {
        name: '',
        account_owner: '',
        payment_type: 'sepa_debit',
        sepa_debit: {
          iban: '',
          bic: '',
        },
      },

      loaded: true,

      payment_type: '',

      paymentOptions: [
        {
          value: 'card',
          title: this.$t('Credit Card'),
          active: true,
        },
        {
          value: 'sepa_debit',
          title: this.$t('Lastschrift'),
          active: false,
        },
      ],

      monthOptions: [
        {
          text: this.$t('Choose a month'),
        },
        {
          value: '01',
          text: '01'
        },
        {
          value: '02',
          text: '02'
        },
        {
          value: '03',
          text: '03'
        },
        {
          value: '04',
          text: '04'
        },
        {
          value: '05',
          text: '05'
        },
        {
          value: '06',
          text: '06'
        },
        {
          value: '07',
          text: '07'
        },
        {
          value: '08',
          text: '08'
        },
        {
          value: '09',
          text: '09'
        },
        {
          value: '10',
          text: '10'
        },
        {
          value: '11',
          text: '11'
        },
        {
          value: '12',
          text: '12'
        },
      ],
      yearOptions: [
        {
          text: this.$t('Choose a Year'),
        },
        {
          value: '2022',
          text: '2022'
        },
        {
          value: '2023',
          text: '2023'
        },
        {
          value: '2024',
          text: '2024'
        },
        {
          value: '2025',
          text: '2025'
        },
        {
          value: '2026',
          text: '2026'
        },
        {
          value: '2027',
          text: '2027'
        },
        {
          value: '2028',
          text: '2028'
        },
        {
          value: '2029',
          text: '2029'
        },
        {
          value: '2030',
          text: '2030'
        },
        {
          value: '2031',
          text: '2031'
        },
        {
          value: '2032',
          text: '2032'
        },
        {
          value: '2033',
          text: '2033'
        },
      ],
      validation: {},
      validate: false,

    }
  },

  methods: {
    formatCvc(e) {
      return String(e)
          .substring(0, 3)
    },

    formatYear(e) {
      return String(e)
          .substring(0, 4)
    },

    // createItem() {
    //   let url = `/client/paymentmethods`
    //
    //   this.$refs.refFormObserver.validate()
    //       .then((success) => {
    //         this.validation = {}
    //
    //         if (success) {
    //           this.$http.post(url, this.user_sepa_debit)
    //               .then(response => {
    //                 this.$router.push(
    //                     {
    //                       name: 'payment-method-list',
    //                     },
    //                     () => setTimeout(() => {
    //                       this.$bvToast.toast(this.$i18n.t('Payment Method successfully added'), {
    //                         title: this.$i18n.t('Success'),
    //                         variant: 'success',
    //                         toaster: 'b-toaster-bottom-right'
    //                       })
    //                     })
    //                 )
    //               }).catch(error => {
    //             if (error.response.status == 422) {
    //               this.validation = error.response.data.errors;
    //               this.validate = true
    //             } else {
    //               this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
    //                 title: this.$i18n.t(error.response.data.message),
    //                 variant: "danger",
    //                 toaster: "b-toaster-bottom-right",
    //               });
    //             }
    //
    //           })
    //         }
    //       })
    //
    // },

    createItem() {
      let url = `/client/paymentmethods`
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            if (success) {
              this.$http.post(url, this.user_sepa_debit)
                  .then(response => {
                    this.$router.push(
                        {
                          name: 'payment-method-list',
                        },
                        () => setTimeout(() => {
                          this.$bvToast.toast(this.$i18n.t('Payment Method successfully added'), {
                            title: this.$i18n.t('Success'),
                            variant: 'success',
                            toaster: 'b-toaster-bottom-right'
                          })
                        })
                    )
                  })
                  .catch(error => {
                    if (error.response.status == 422) {
                      this.validation = error.response.data.errors;
                      this.validate = true
                    } else {
                      this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                        title: this.$i18n.t(error.response.data.message),
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                      });
                    }
                  })
            }
          })
    },

    getValidationState({
                         dirty,
                         validated,
                         valid = null
                       }) {
      return dirty || validated ? valid : null
    }
    ,

  },

}
</script>

<style>
</style>
